import React, { useState } from "react";
import useShowNav from "../hooks/useShowNav";
import { graphql, useStaticQuery } from "gatsby";
import MenuButton from "./icons/MenuButton"

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const displayNav = useShowNav(navbarOpen);

  const query = graphql`
    query NavbarQuery {
      strapiHeader {
        Logo {
          alternativeText
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }    
  `;

  const data = useStaticQuery(query);
  const logo = data.strapiHeader.Logo;

  const navItems = () => {
    if(!props.isSingleSite){
      return (
        <ul className="flex flex-col md:flex-row list-none lg:ml-auto h-full cursor-pointer">
        <li className="nav-item">
            <a
              href="/"
              className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/paddock-view"
              className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
            >
              Paddock View
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/stable-view"
              className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
            >
              Stable View
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/contact-us"
              className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
            >
              Contact Us
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/availability"
              className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
            >
              Availability
            </a>
          </li>
        </ul>
      );
    }
    else
    {
      return (
      <ul className="flex flex-col md:flex-row list-none lg:ml-auto h-full cursor-pointer">
      <li className="nav-item">
          <a
            href="/"
            className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
          >
            Home
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/contact-us"
            className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
          >
            Contact Us
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/availability"
            className="px-3 py-2 flex items-center text-sm uppercase leading-snug text-white hover:underline"
          >
            Availability
          </a>
        </li>
      </ul>
      );
    }
  }

  return (
    <>
      <header className="flex flex-wrap flex-row justify-between md:items-center md:space-x-4 bg-transparent py-6 px-6 relative mx-auto xl:w-3/5 lg:w-4/5 w-full">
        <a href="/" className="block">
          <img
            className="h-16 xl:h-24"
            src={logo.localFile.childImageSharp.fluid.src}
            alt={logo.alternativeText ?? "image"}
            title={logo.alternativeText ?? "image"}
          />
        </a>
        <button
          className="inline-block md:hidden w-8 h-8 text-gray-600 p-1"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <MenuButton />
        </button>
        <nav className="absolute md:relative flex top-24 left-0 md:top-0 z-20 flex-col md:flex-row md:space-x-6 font-semibold w-full md:w-auto bg-primary shadow-md md:rounded-none md:shadow-none md:bg-transparent p-6 pt-0 md:p-0">
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen || displayNav ? " flex" : " hidden")
            }
          >
            {navItems()}
          </div>
        </nav>
      </header>
    </>
  );
}
