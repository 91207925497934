import React from "react";

export default function Container(props) {
  return (
    <div className={`container mx-auto p-6 my-4 xl:w-3/5 lg:w-4/5 ${props.className} ${props.transparent ? "bg-transparent" : "bg-white"}`} style={props.styles}>
        {props.children}
    </div>
  );
}

Container.defaultProps = {
  styles : undefined,
  transparent: false
};

