import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FooterSocialLink from "./FooterSocialLink";
import Facebook from "./icons/Facebook";
import Twitter from "./icons/Twitter";
import Instagram from "./icons/Instagram";

export default function Footer() {
  const query = graphql`
    query {
      strapiFooter {
        Facebook
        Instagram
        Twitter
      }
    }
  `;
  const data = useStaticQuery(query);
  return (
    <>
      <footer className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg mb-3 mx-auto xl:w-3/5 lg:w-4/5 ">
        <div className="container px-4 mx-auto flex  flex-col flex-wrap items-center justify-between">
          <ul className="flex mx-auto text-white text-center">
            <a href="/terms-and-conditions">
              <li className="p-2 cursor-pointer hover:underline">
                Terms and Conditions
              </li>
            </a>
            <a href="/privacy-policy">
              <li className="p-2 cursor-pointer hover:underline">
                Privacy policy
              </li>
            </a>
            <a href="/cookie-policy">
              <li className="p-2 cursor-pointer hover:underline">
                Cookie Policy
              </li>
            </a>
            <a href="/sitemap.xml">
              <li className="p-2 cursor-pointer hover:underline">Sitemap</li>
            </a>
          </ul>
          <ul className="flex mx-auto text-white text-center">
            <FooterSocialLink link={data.strapiFooter.Facebook}>
              <Facebook />
            </FooterSocialLink>
            <FooterSocialLink link={data.strapiFooter.Instagram}>
              <Instagram />
            </FooterSocialLink>
            <FooterSocialLink link={data.strapiFooter.Twitter}>
              <Twitter />
            </FooterSocialLink>
          </ul>
          <div className="flex mx-auto text-white text-center">
            Copyright © West Knole House {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    </>
  );
}
