import React from "react";

export default function FooterSocialLink(props) {
  return (
    <li className="p-2 cursor-pointer hover:pulse">
      <a href={props.link} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    </li>
  );
}
