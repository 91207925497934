import { useState, useEffect } from "react";

export default function useShowNav(isNavOpen) {
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowWidth(window.innerWidth);
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    
    if(!isNavOpen && windowWidth !== undefined && windowWidth > 767){
        return true
    }
    else if(isNavOpen && windowWidth <= 768)
    {
        return true;
    }

    return false;
  }